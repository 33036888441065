<template lang="pug">
  v-container
    div(
      v-for="(item, index) in links" :key="index"
      style="display: inline"
    )
      v-btn.mr-2(
        v-if="$route.name !== item.path"
        :color="item.color"
        dark
        dense
        @click="$router.push(item.params)"
      )
        span {{ item.text }}
    router-view.mt-2
</template>
<script>
const varLinks = () => [
  { path: 'orders.billings.create', text: 'New', color: 'green', params: { name: 'orders.billings.create' } },
  { path: 'orders.billings.table', text: 'Index', color: 'red', params: { name: 'orders.billings.table' } },
]
export default {
  name: 'Billings',
  components: {
    tableBilling: () => import('./Table'),
  },
  data () {
    return {
      links: varLinks(),
    }
  },
}
</script>